import { Component, OnInit } from '@angular/core';
import { FirebaseService } from './../../services/firebase.service';
import { ApiService } from './../../services/api.service';

import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';

import firebase from "firebase";

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
  displayedColumns: string[] = ['node', 'model', 'test'];
  dataSource: any = [];
  machineId: any;
  fbase: any;
  srceen_data : any = '';
  type: any;
  width: any ;
  wmType: any;
  addmore_status = 0;
  add_value: any;
  spin = false;
  recount: any;
  count = 10 ;
  status = '';
  roomm: any;
  generateQr:any = false;
  breakpoint : any;
  table_breakpoint: any = 1;
  rowHeight : any = '580';

  constructor(
    public apiService : ApiService,
    public firebaseService: FirebaseService,
    private router: Router,
    private Activatedroute: ActivatedRoute,
  ) { }

 async ngOnInit() {
      
      this.fbase = await this.firebaseService.getcon();

      if (window.screen.availWidth < 769) {
          this.type = 'moblie'
          if (window.screen.availWidth < 321) {
            this.width  = 285;
          }
      } else {
          this.type = 'ipad'
          
      }
      if (window.screen.availWidth >= 1024) {
        this.breakpoint = 3;
        this.table_breakpoint =2;
      } else {
        this.breakpoint = 1;
        this.table_breakpoint = 1;
      }
     let id = await this.Activatedroute.snapshot.paramMap.get('roomId');
     const result = await this.apiService.wmroom(id)
     if (result == false) {
      this.status = 'error';
      } else {
          this.status = '';
          if (result.arr.length <= 9) {
            this.rowHeight ='580';
         } else if (result.arr.length > 9 && result.arr.length <= 15) {
            this.rowHeight ='850';
         } else if (result.arr.length > 15 && result.arr.length <= 20) {
            this.rowHeight ='1135';
         }  else if (result.arr.length > 20) {
            this.rowHeight ='1260';
         }
         this.dataSource = result.arr;
         this.roomm = result.room;
         this.spin = true;
      }
  }


async  addMachine(data : any) {
      // console.log(event);
      this.machineId = data.id;
      // console.log(this.machineId);

          const answer = await this.apiService.addMachine(this.machineId);
          // console.log(answer);
          if (answer == false) {
            alert('ไม่พบเลขเครื่องนี้ กรุณาลองใหม่')
            this.machineId = '';
            this.srceen_data = '';
            return
          }
          await this.sleep(1500);
          this.fbase.database().ref('/machineId/' + this.machineId).on('value', async (snapshot: any) => {
              // console.log(snapshot.val());
              if (snapshot.val() == undefined || snapshot.val() == '') {
                  alert('ไม่พบเลขเครื่องนี้ กรุณาลองใหม่')
                  this.machineId = '';
              } else {
                  // console.log(snapshot.val());
                
                  this.srceen_data = snapshot.val();
                  // console.log(snapshot.val().id);
                  
                  if (this.srceen_data.statusId == 'AVAILABLE') {
                      this.generateQr = true;
                      await this.sleep(500);
                      this.generateQr = false;
                  }
                  const result = this.dataSource.find((id: any) => id.id +'' === snapshot.val().id);
                  // console.log(result);
                  this.srceen_data.node = result.node;
                  this.wmType = snapshot.val().type;
              }
          });

  }

  sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  deleteMachine() {
    // console.log(this.machineId);
    this.apiService.deleteMachine(this.machineId);
    this.machineId = '';
    this.srceen_data = '';
  }

  addmoretime(){
    this.addmore_status = 1;
  }

  back(){
    this.addmore_status = 0;
  }

  add(data: any) {
    this.addmore_status = 2;
    this.add_value = data;
    this.time()
  }

  time() {
      if (this.count > 0) {
        this.recount = setTimeout(() => {
          this.count = this.count - 1;
          this.time();
        }, 1000);
      } else {
          clearInterval(this.recount);
          this.addmore_status = 0;
          this.count = 10;
      }
  }

  onResize(event: any) {
    if (event.target.innerWidth >= 1024) {
      this.breakpoint = 3;
    } else {
      this.breakpoint = 1;
    }

    if (window.screen.availWidth >= 1024) {
      this.breakpoint = 3;
      this.table_breakpoint =2;
    } else {
      this.breakpoint = 1;
      this.table_breakpoint = 1;
    }
    // this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  }

}
