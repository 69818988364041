import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
// declare var io: any;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // partUrl : any = 'http://localhost:7900/';
  partUrl: any = 'http://45.77.34.193:7900/';
  mode: any;

  constructor(
    private http: HttpClient,
    public router: Router,
    // public io: Socket,

  ) { }


  addMachine(machineId: any) {
    const url = this.partUrl + 'addMachine';

    return this.http.post(url, {machineId})
    .toPromise()
    .then(result => result)
    .catch(error => error);
  }


  deleteMachine(machineId: any) {
   const url = this.partUrl + 'deleteMachine';

    return this.http.post(url, {machineId})
    .toPromise()
    .then(result => result)
    .catch(error => error);
  }

  all() {
   const url = this.partUrl + 'all';

    return this.http.get(url)
    .toPromise()
    .then(result => result)
    .catch(error => error);
  }


  wmroom(id: any) {
    const url = this.partUrl + 'wm-room';

     return this.http.post(url, {id})
     .toPromise()
     .then(result => result)
     .catch(error => error);
   }

  gen(id: any) {
    const url = this.partUrl + 'gen';

     return this.http.post(url, {id})
     .toPromise()
     .then(result => result)
     .catch(error => error);
   }
}

