<!-- <h1>ชื่อร้าน หรือ สถานที่ตั้งของร้าน เป็นภาษาอังกฤษ</h1> -->
<br>
<br><br>
<mat-form-field appearance="standard" style="width: 90%; margin: auto; display: block; text-align: center; ">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="ชื่อร้าน" #input>
</mat-form-field>
<br>

<table *ngIf="showTable" mat-table [dataSource]="dataSource" class="mat-elevation-z8">


  <ng-container matColumnDef="name" >
    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="go(element)" > {{element.name}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="geo" >
    <mat-header-cell *matHeaderCellDef> Geo </mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="go(element)" > {{element.geo}} </mat-cell>
  </ng-container>

  <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>
