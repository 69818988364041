<br><br>

<h1 style="text-align: center;" *ngIf="roomm">{{roomm.rooomName}}</h1>
<h1 style="text-align: center;">เลือกเครื่องที่ต้องการทดลองระบบ justwash@connect</h1>

  <p *ngIf="status !== ''" style="text-align: center;">มีบางอย่างผิดพลาด</p>
  <p *ngIf="spin == false" style="text-align: center;">รอสักครู่</p>

  <mat-spinner *ngIf="spin == false" style="margin: auto;" [diameter]="30"></mat-spinner>
  <br><br>
  
  <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight"  (window:resize)="onResize($event)">

    <mat-grid-tile  [colspan]="table_breakpoint">
      <table  *ngIf="dataSource" mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="position: absolute; top: 0px;" >

        <ng-container matColumnDef="node" >
          <mat-header-cell *matHeaderCellDef> node </mat-header-cell>
          <mat-cell *matCellDef="let element" > {{element.node}} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="model" >
          <mat-header-cell *matHeaderCellDef> model </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.model}} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="test" >
          <mat-header-cell *matHeaderCellDef> test </mat-header-cell>
          <mat-cell *matCellDef="let element"><button mat-stroked-button color="primary" (click)="addMachine(element)">test</button></mat-cell>
        </ng-container>
    
        <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </table>
    
    </mat-grid-tile>
    <mat-grid-tile>

<div class="container" style="position: absolute; top: 0px;" >
    <p style="text-align: center; margin: auto; font-size: larger;" *ngIf="srceen_data">{{srceen_data.node}} showing </p><br>

    <img *ngIf="srceen_data  == '' || generateQr == true" src="./../assets/img/qrscreen.png"  [ngStyle]="{'width' : width + 'px' }">
   
    <div *ngIf="generateQr == true">
      <p id="genQr"> generate qrcode </p>
      <mat-progress-bar id="dot" mode="buffer" ></mat-progress-bar>  
    </div>
  
    <div *ngIf="wmType == 'Washer' && srceen_data  && generateQr == false">
         <img *ngIf="srceen_data.statusId == 'AVAILABLE'"  src="./../assets/img/qrscreen1.png"  [ngStyle]="{'width' : width + 'px' }">
         <img *ngIf="srceen_data.statusId == 'READY_TO_START' || srceen_data.statusId == 'IN_USE'|| srceen_data.statusId == 'COMPLETE'"  src="./../assets/img/processingscreen.png"  [ngStyle]="{'width' : width + 'px' }">

    </div>
    <div *ngIf="wmType == 'Dryer' && srceen_data && generateQr == false">
        <img *ngIf="srceen_data.statusId == 'AVAILABLE'"  src="./../assets/img/qrscreen1.png"  [ngStyle]="{'width' : width + 'px' }">
        <img *ngIf="(srceen_data.statusId == 'READY_TO_START' || srceen_data.statusId == 'IN_USE' || srceen_data.statusId == 'COMPLETE') && addmore_status == 0"  src="./../assets/img/processingsreenwithaddmore.png"  [ngStyle]="{'width' : width + 'px' }">
        <div id="addmoretime" style="cursor: pointer;" *ngIf="addmore_status  == 0" (click)="addmoretime()"></div>
        <img *ngIf="addmore_status  == 1" style="z-index: 100;"  src="./../assets/img/addtimescreen.png"  [ngStyle]="{'width' : width + 'px' }">
        <div id="back" style="cursor: pointer;" *ngIf="addmore_status == 1" (click)="back()"></div>

        <div id="add10" style="cursor: pointer;" *ngIf="addmore_status == 1" (click)="add(10)"></div>
        <div id="add20" style="cursor: pointer;" *ngIf="addmore_status == 1" (click)="add(20)"></div>
        <div id="add30" style="cursor: pointer;" *ngIf="addmore_status == 1" (click)="add(30)"></div>
        <div id="add40" style="cursor: pointer;" *ngIf="addmore_status == 1" (click)="add(40)"></div>
        <img *ngIf="addmore_status  == 2" src="./../assets/img/qrscreen1.png"  [ngStyle]="{'width' : width + 'px' }">
        <span *ngIf="addmore_status  == 2" id="add">Add More &nbsp;&nbsp; {{add_value}}</span>
        <span *ngIf="addmore_status  == 2" id="count">{{count}}</span>

    </div>


    <div class="text-block" *ngIf="srceen_data.statusId == 'AVAILABLE'">
      <span style="color: #133d65;"> {{srceen_data.selectedCycle}} &nbsp;&nbsp;  {{srceen_data.remainingVend}} </span>
    </div>
    <div class="text-block" 
      *ngIf="srceen_data.statusId !== 'AVAILABLE' && srceen_data.statusId !== 'READY_TO_START' && srceen_data.statusId !== 'IN_USE' && srceen_data.statusId !== 'COMPLETE'">
      <b>{{srceen_data.statusId}}</b>
    </div>

</div>
    </mat-grid-tile>
  
  </mat-grid-list>
 

<br>

