import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemoComponent  } from './demo/demo.component';
import { RoomComponent  } from './room/room.component';


const routes: Routes = [
  {path: 'demo/:roomId', component: DemoComponent },
  {path: '', component: DemoComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
