import { Component, OnInit } from '@angular/core';
import { ApiService } from './../../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {
  origin: any;
  showTable = false;
  displayedColumns: string[] = ['name', 'geo'];
  dataSource: any = [];

  constructor(
    private router: Router,
    public apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.getroom();
  }

  async getroom(){
    const result = await this.apiService.all();
    console.log(result);
    this.origin = result;
    this.dataSource =result;

  }

  applyFilter(event: Event) {

    if ((event.target as HTMLInputElement).value == '') {
      this.showTable = false;
    } else {
      this.showTable = true;
    }

    var dataSource = new MatTableDataSource(this.origin);
    const filterValue = (event.target as HTMLInputElement).value;
    dataSource.filter = filterValue.trim().toLowerCase();
    // console.log(dataSource.filteredData);
    this.dataSource = dataSource.filteredData;
  }

  async  go(data: any) {
    // console.log(data);
    const result = await this.apiService.gen(data.roomId);
    // console.log(result);
    this.router.navigate(['demo/'+ result]);
  }

}


