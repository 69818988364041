import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatButtonModule} from '@angular/material/button';
import { MatSelectModule} from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatTableModule} from '@angular/material/table';
import { MatIconModule} from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule} from '@angular/material/card';
import { MatRadioModule} from '@angular/material/radio';
// import { FlexLayoutModule} from '@angular/flex-layout';
import { MatTreeModule} from '@angular/material/tree';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { MatDialogModule} from '@angular/material/dialog';
import { MatDividerModule} from '@angular/material/divider';
import { MatTabsModule} from '@angular/material/tabs';
import { MatSortModule} from '@angular/material/sort';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoComponent } from './demo/demo.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';


import { environment } from './../environments/environment';
import { RoomComponent } from './room/room.component'
@NgModule({
  declarations: [
    AppComponent,
    DemoComponent,
    RoomComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    FormsModule,
    HttpClientModule,
    MatCardModule,
    MatSliderModule,
    DragDropModule,
    MatGridListModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    HttpClientModule,
    MatSidenavModule,
    MatTableModule,
    MatStepperModule,
    MatCardModule,
    MatRadioModule,
    // FlexLayoutModule,
    MatTreeModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatSelectFilterModule,
    MatProgressBarModule,
    MatDialogModule,
    MatDividerModule,
    MatTabsModule,
    MatIconModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
