
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
// declare var io: any;
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  firebaseConfig = {
    apiKey: "AIzaSyAPYMHa_V7oN_t9roA3z1hLKfQ_QT2cc3o",
    authDomain: "fir-demo-ce1f1.firebaseapp.com",
    databaseURL: "https://fir-demo-ce1f1-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "fir-demo-ce1f1",
    storageBucket: "fir-demo-ce1f1.appspot.com",
    messagingSenderId: "948271287417",
    appId: "1:948271287417:web:26dfd751fe46cb00d618a8",
    measurementId: "${config.measurementId}"
  };
  fbase: any;

  constructor(
    private http: HttpClient,
    public router: Router,

  ) {
    this.fbase = firebase.initializeApp(this.firebaseConfig, 'demo');
  }


  getcon() {
      return this.fbase
  }

}
